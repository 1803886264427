import Row from "antd/lib/row";
import Markdown from "markdown-to-jsx";
import React from "react";

import { faqContent } from "../markdown/faq";

const Faq = () => {
  return (
    <Row justify="center">
      <div style={{ maxWidth: 960 }}>
        <Markdown>{faqContent}</Markdown>
      </div>
    </Row>
  );
};

export default Faq;
